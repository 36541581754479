import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BodyLinkDark from "./body-link-dark"

const Footer = () => {
  const logo = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo-jfa.png" }) {
        childImageSharp {
          fluid(maxWidth: 100, fit: COVER) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div className="bg-mortino-900 ">
      <div className="w-5/6 mx-auto justify-center flex items-center py-6">
        <div className="w-16 mr-4">
          <Img fluid={logo.file.childImageSharp.fluid} />
        </div>
        <div className="text-gray-200  text-xs tracking-widest">
          <h1 className="text-sm font-bold">Soluciones Tecnológicas Web</h1>
          <h2>Juan Francisco Andrade A.</h2>
          <h3>Consultor Independiente</h3>
          <span className="text-sm">
            <BodyLinkDark
              url="https://www.jandrade.co"
              content="www.jandrade.co"
            />
          </span>
        </div>
      </div>
    </div>
  )
}

export default Footer
