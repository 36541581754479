import React from "react"
import ProductFeatures from "./product-features"

const Product = () => {
  return (
    <div id="product-section" className="relative pb-12 bg-mortino-900">
      <svg
        className="w-full"
        height="93"
        viewBox="0 0 1440 93"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <path
          d="M0 0H1440V59.5C846.331 112.979 527.871 107.729 0 0Z"
          fill="#fffdfa"
        />
      </svg>

      <div className="pt-20 ">
        <ProductFeatures />
      </div>
    </div>
  )
}

export default Product
