import React from "react"

const BodyLink = ({ url, content }) => {
  return (
    <a className="body-link link-dark" href={url}>
      {content}
    </a>
  )
}

export default BodyLink
