import React from "react"
import sketchBookSvg from "../../images/sketch-book.svg"

const SketchBookIcon = () => {
  return (
    <object
      id="icon-sketch-book"
      width="48"
      heigh="48"
      type="image/svg+xml"
      data={sketchBookSvg}
    >
      <div></div>
    </object>
  )
}

export default SketchBookIcon
