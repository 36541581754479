import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
const About = () => {
  const photo = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "foto_n.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <div className="bg-mortino-800 ">
      <div className="md:flex flex-wrap  items-center ">
        <div className="w-full md:w-1/2 ">
          <Img
            className="p-10 shadow rounded-full border-4 
            border-yellow-sun  mx-auto bg-mortino-100 
            h-48 w-48 flex items-center justify-center"
            fluid={photo.file.childImageSharp.fluid}
          />
        </div>
        <div className="w-full px-20 md:w-1/2 md:-ml-16  md:px-10 md:py-20">
          <h1 className="text-gray-200 font-sans text-2xl mb-10">
            ¿Quién desarrollará tu Sitio Web?
          </h1>
          <p className="text-gray-100 text-base">
            Soy Juan Francisco Andrade. Soy Ingeniero de Sistemas, y me
            especializo en la generación de Soluciones Web. Tengo muchos años de
            experiencia en esta industria y mi principal objetivo es brindarte
            el mejor servicio profesional en la creación de su Sitio o
            Aplicación Web. Entre la gran variedad de tecnologías, paradigmas
            tecnológicos, y un anális adecuado, encontraremos la mejor solución
            a tus requerimientos!
          </p>
        </div>
      </div>
    </div>
  )
}

export default About
