import React from "react"

const Feature = ({ icon, detail }) => {
  return (
    <div className="sm:w-1/2 sm:flex-auto  sm:px-5 mb-10">
      <div className=" mb-6">{icon}</div>
      <div>
        <p className="max-w-sm justified-text">{detail}</p>
      </div>
    </div>
  )
}

export default Feature
