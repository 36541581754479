import React from "react"

const ListItemWithIcon = ({ icon, detail, align }) => {
  return (
    <div
      className={` flex items-${align} flex-wrap md:flex-no-wrap flex-auto mb-6 md:mb-8`}
    >
      <div className="mr-4 mb-3 md:mb-0">{icon}</div>
      <div>
        <div className="text-gray-100">{detail}</div>
      </div>
    </div>
  )
}

export default ListItemWithIcon
