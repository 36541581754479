import React from "react"
import MotivationItem from "./motivation-item"

import ProfessionalSvg from "../images/professional.svg"
import BodyLink from "./body-link"

const Motivation = () => {
  return (
    <section id="about-web-section" className="mt-16 md:mt-24 content-wrapper">
      <h1 className="section-title">¿Por qué necesito tener un Sitio Web?</h1>
      <p className="section-paragraph">
        Ser propietario de un Sitio Web es primordial en el mundo actual en el
        que nos desenvolvemos. Ya sea para un uso profesional o empresarial, un
        Sitio Web puede abrirte muchas{" "}
        <BodyLink
          url="https://blog.jandrade.co/beneficios-soluciones-web"
          content="puertas hacia el éxito"
        />
        .
      </p>

      <p className="section-paragraph mt-4 ">
        Tu presencia en la Web, puede darse de la siguiente manera:
      </p>

      <div className=" md:flex flex-wrap  items-center">
        <div className="mt-12 md:mt-8 lg:w-1/3">
          <MotivationItem>
            Dar a conocer al mundo quién eres y qué es lo que haces. Podrás
            presentar tu perfil profesional o empresarial: su historia, sus
            propietarios, sus equipos de trabajo, etc.
          </MotivationItem>
          <MotivationItem>
            Mediante un blog, compartir el conocimiento que has llegado a
            adquirir durante los años que llevas trabajando en tu negocio.
            También pueden ser casos de estudio o casos de éxito en los trabajos
            o proyectos realizados.
          </MotivationItem>
        </div>
        <div className="w-64 md:w-5/6 lg:w-1/3 mx-auto  md:mx-0 flex-1 ">
          <object
            id="professional-svg"
            className="w-5/6 md:w-3/4 lg:w-2/3 mx-auto"
            type="image/svg+xml"
            data={ProfessionalSvg}
          >
            <div></div>
          </object>
        </div>
        <div className=" flex-1  mt-12 md:mt-8  lg:w-1/3">
          <MotivationItem>
            ¿Qué productos o servicios ofreces? Puedes tener un portafolio o
            catálogo de los productos y servicios que tu negocio ofrece.
          </MotivationItem>
          <MotivationItem>
            Cómo tus futuros clientes pueden ubicar tu negocio y contactarte así
            como también facilitarles un formulario desde el cual pueden
            enviarte sus requerimientos, o establecer una primera reunión de
            trabajo!.
          </MotivationItem>
        </div>
      </div>

      <p className="mt-6  section-paragraph  ">
        Y mucho más. En realidad un Sitio Web puede tener mucha información y
        muchas herramientas (features), pero nos enfocaremos inicialmente en
        crear un{" "}
        <BodyLink
          url="https://es.wikipedia.org/wiki/Producto_viable_m%C3%ADnimo"
          content="Producto Mínimo Viable"
        />
        , con el cual puedas arrancar.
      </p>
    </section>
  )
}

export default Motivation
