import React from "react"
import Feature from "./feature"
import WebPagesIcon from "./icons/web-pages-icon"
import ProductReleaseIcon from "./icons/product-release-icon"
import ResearchIcon from "./icons/research-icon"
import WebDesignIcon from "./icons/web-design-icon"
import SketchBookIcon from "./icons/sketch-book-icon"
import UpgradeSvg from "../images/upgrade.svg"
import Bold from "./bold"
import BodyLink from "./body-link"

const Features = () => {
  return (
    <section id="features-section" className=" mt-16 content-wrapper">
      <h1 className="section-title"> ¿En qué consistirá mi Sitio Web?</h1>
      <p className="section-paragraph  ">
        Tu Sitio Web, consistirá en un Producto Mínimo Viable, que tendrá los
        siguientes componentes:
      </p>
      <div className="mt-12 sm:flex sm:flex-wrap sm:-mx-5">
        <Feature
          icon={<WebPagesIcon />}
          detail={
            <p>
              <Bold>
                Un conjunto de páginas o documentos Web que conformarán tu Sitio
              </Bold>
              . Cada documento tendrá la información y herramientas que quieras
              otorgar a tus visitantes: portafolio, blog, ubicación, etc.
            </p>
          }
        />
        <Feature
          icon={<ProductReleaseIcon />}
          detail={
            <p>
              <Bold>Un Servicio de Hosting</Bold>, donde alojaremos tu Sitio
              Web. Es como una oficina o una casa donde tu negocio podrá ser
              visitada desde cualquier lugar del mundo en el Internet.
            </p>
          }
        />
        <Feature
          icon={<ResearchIcon />}
          detail={
            <p>
              <Bold>Nombre de dominio.</Bold> Tu nombre profesional o
              empresarial estará representado en el Internet mediante tu{" "}
              <BodyLink
                url="https://es.wikipedia.org/wiki/Dominio_de_Internet"
                content="Nombre de Dominio"
              />
              , por ejemplo: <Bold>migrannegocio.com</Bold>. Este será el nombre
              con el cual tus futuros clientes te identifiquen, será la{" "}
              <BodyLink
                url="https://es.wikipedia.org/wiki/Localizador_de_recursos_uniforme"
                content="URL "
              />
              que ellos introduzcan en la barra de direcciones de un browser
              para ubicar tu negocio en el Internet, y será el nombre que
              <Bold> Google </Bold>
              muestre en sus resultados de búsqueda.
            </p>
          }
        />
        <Feature
          icon={<WebDesignIcon />}
          detail={
            <p>
              <Bold>Diseño gráfico</Bold>, que consiste en una Interfaz visual
              con la cual interactúan los visitantes de tu Sitio Web.
              Consideramos un conjunto de principios de diseño que incluyen
              color, tipo de letra (tipogragía), disposición de contenido, como
              imágenes, texto, etc (layout); todo para que tu Sitio Web sea
              utilizado de la manera más amigable y tus visitantes no lo
              abandonen.
            </p>
          }
        />
        <Feature
          icon={<SketchBookIcon />}
          detail={
            <p>
              {" "}
              <Bold>Un Software</Bold> en el cual podrás editar la información
              de tu negocio que vas a presentar en tu Sitio Web. Debido a que un
              negocio cambia constantemente, toda la información que quieras
              presentar no puede mantenerse de manera estática en una página
              Web, entonces es necesaria una herramienta donde puedas crear y
              modificarla cuando tu lo necesites. para ello utilizaremos un
              Software llamado{" "}
              <BodyLink
                url="https://es.wikipedia.org/wiki/Sistema_de_gesti%C3%B3n_de_contenidos"
                content="CMS (Content Management System)"
              />
            </p>
          }
        />
        <div className="sm:w-1/2 flex-auto self-start">
          <object
            id="upgrade-svg"
            className="w-1/2 sm:w-3/4 mx-auto"
            type="image/svg+xml"
            data={UpgradeSvg}
          >
            <div></div>
          </object>
        </div>
      </div>
    </section>
  )
}

export default Features
