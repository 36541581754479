import React from "react"
import productReleaseSvg from "../../images/product-release.svg"

const ProductReleaseIcon = () => {
  return (
    <object
      id="icon-product-release"
      width="48"
      heigh="48"
      type="image/svg+xml"
      data={productReleaseSvg}
    >
      <div></div>
    </object>
  )
}

export default ProductReleaseIcon
