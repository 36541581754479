import React from "react"
import researchSvg from "../../images/research.svg"

const ResearchIcon = () => {
  return (
    <object
      id="icon-research"
      width="48"
      heigh="48"
      type="image/svg+xml"
      data={researchSvg}
    >
      <div></div>
    </object>
  )
}

export default ResearchIcon
