import React from "react"
import Benefits from "./benefits"

const ProductFeatures = () => {
  return (
    <div className="content-wrapper">
      <Benefits />
    </div>
  )
}

export default ProductFeatures
