import React from "react"
import webPagesSvg from "../../images/web-tool.svg"

const WebPagesIcon = () => {
  return (
    <object
      id="icon-web-pages"
      width="48"
      heigh="48"
      type="image/svg+xml"
      data={webPagesSvg}
    >
      <div></div>
    </object>
  )
}

export default WebPagesIcon
