import React from "react"

const BodyLinkDark = ({ url, content }) => {
  return (
    <a className="body-link link-light" href={url}>
      {content}
    </a>
  )
}

export default BodyLinkDark
