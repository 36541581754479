import React from "react"
import FeaturesCard from "./features-card"
import ListItemWithIcon from "./list-item-with-icon"
import SeoIcon from "./icons/seo-icon"
import PerformanceIcon from "./icons/performance-icon"
import MobileDevicesIcon from "./icons/mobile-devices-icon"
import PluginIcon from "./icons/plugin-icon"
import TargetIcon from "./icons/target-icon"
import WorldAroundImage from "../images/world-around.svg"
import CtaButton from "./cta-button"
import Bold from "./bold"
import BodyLinkDark from "./body-link-dark"

const Benefits = () => {
  return (
    <div
      id="benefits-section"
      className="pt-12 lg:flex lg:-mx-10 md:flex-wrap "
    >
      <FeaturesCard>
        <div className="p-12">
          <h1 className="text-gray-200 font-sans text-xl mb-8">
            Beneficios del producto Web
          </h1>
          <ListItemWithIcon
            detail={
              <p>
                <Bold>
                  {" "}
                  Orientado a{" "}
                  <BodyLinkDark
                    url="https://es.wikipedia.org/wiki/Posicionamiento_en_buscadores"
                    content="SEO (Search Engine Optimization)"
                  />{" "}
                </Bold>
                . Tu sitio Web, será configurado para que <Bold>Google</Bold> lo
                descubra y lo indexe, para que de esta manera tengas más
                probabilidades de que las personas te encuentren en el cyber
                espacio.{" "}
              </p>
            }
            icon={<SeoIcon />}
          />
          <ListItemWithIcon
            detail={
              <>
                <p>
                  <Bold>Gran rendimiento</Bold>. Tu Sitio Web no dejará
                  esperando a tus visitantes mientras se carga. En muchos
                  Sitios, al suceder esto, los usuarios ni siquiera esperan a
                  que se carguen las páginas y desisten de visitar un Sitio Web,
                  lo que obviamente no es nada favorable para ti, el
                  propietario, pues puedes perder muchas oportunidades.{" "}
                </p>
                <p className="mt-4">
                  Al crear tu Sitio Web, utilizaremos técnicas que se acoplan a
                  principios del patrón{" "}
                  <BodyLinkDark
                    url="https://developers.google.com/web/fundamentals/performance/prpl-pattern/?hl=es"
                    content="PRPL de Google"
                  />
                  , para que tu Sitio Web sea eficiente en todo tipo de
                  dispositivo: smartphones, desktop, etc.
                </p>
              </>
            }
            icon={<PerformanceIcon />}
          />
          <ListItemWithIcon
            detail={
              <p>
                <Bold>Tu sitio Web será Responsive</Bold>, es decir que podrán
                visualizarlo en cualquier tipo de dispositivo, ya sea en un
                smartphone, en una tablet o iPad o en un computador de
                escritorio, sin perder su correcta visualización e interacción.
                Puedes estar tranquilo/a ya que tus visitantes se sentirán
                cómodos utilizando tu Sitio Web, y no lo abandonarán, o no
                tendrán que cambiar de dispositivo.
              </p>
            }
            icon={<MobileDevicesIcon />}
          />
          <ListItemWithIcon
            detail={
              <p>
                <Bold>Escalable</Bold>. Tu producto Web, siempre podrá
                evolucionar. Su <Bold>arquitectura</Bold> permitirá que en el
                futuro, cuando lo creas pertinente, puedas añadir, cambiar o
                eliminar features o información.
              </p>
            }
            icon={<PluginIcon />}
          />
        </div>
      </FeaturesCard>

      <FeaturesCard>
        <div className="p-12 ">
          <h1 className="text-gray-200 font-sans text-xl mb-8">Ideal para:</h1>
          <ListItemWithIcon
            detail={
              <p>
                <Bold>Microemprendedimientos</Bold>. Desde tus primeros pasos
                como emprendedor la presencia Web debe ser de mucha importancia.
                Lleva tu emprendimiento al mundo digital donde los negocios son
                cada vez más frecuentes!
              </p>
            }
            icon={<TargetIcon />}
          />
          <ListItemWithIcon
            detail={
              <p>
                <Bold>Profesionales Independientes (freelancers)</Bold>. Tu
                Sitio Web será una herramienta que fortalecerá tu perfil
                profesional. Tu presencia online visibilizará tus capacidades y
                fortalezas en cualquier área en la que te desenvuelves. Amplía
                tus oportunidades profesionales en el cyber espacio!
              </p>
            }
            icon={<TargetIcon />}
          />
          <ListItemWithIcon
            detail={
              <p>
                <Bold>Negocios con trayectoria</Bold>. Si tu empresa es
                reconocida ampliamente en el mundo físico pero no tiene
                presencia Web, nunca es tarde para empezar y dar relevancia
                digital a tu emprendimiento!. Recuerda que el negocio que no
                tiene presencia Web se vuelve obsoleto.
              </p>
            }
            icon={<TargetIcon />}
          />
        </div>
        <div className="bg-yellow-desert target-cta p-8 md:-mt-12">
          <div className="md:py-8 px-0 flex justify-between md:items-center flex-wrap md:flex-no-wrap">
            <object
              className="mx-auto pb-4 md:pb-0"
              id="icon-product-release"
              width="100"
              heigh="100"
              type="image/svg+xml"
              data={WorldAroundImage}
            >
              <div></div>
            </object>
            <div>
              <h3 className="text-xl text-mortino-700 font-bold mb-2">
                Y para todo el mundo!
              </h3>
              <p className="text-lg mb-6 tracking-wide text-mortino-700">
                Es tiempo que seas propietaria/o de un Sitio Web y seas parte
                del cyber espacio donde las oportunidades y la imaginación no
                tienen límites!
              </p>
              <CtaButton />
            </div>
          </div>
        </div>
      </FeaturesCard>
    </div>
  )
}

export default Benefits
