import React from "react"
import webDesignSvg from "../../images/web-design.svg"

const WebDesignIcon = () => {
  return (
    <object
      id="icon-web-design"
      width="48"
      heigh="48"
      type="image/svg+xml"
      data={webDesignSvg}
    >
      <div></div>
    </object>
  )
}

export default WebDesignIcon
