import React from "react"

import Layout from "../components/layout"
/* import Image from "../components/image" */
import SEO from "../components/seo"
import Banner from "../components/banner"
import Motivation from "../components/motivation"
import Features from "../components/features"
import Product from "../components/product"
import Contact from "../components/contact"
import Footer from "../components/footer"
import About from "../components/about"

const IndexPage = () => (
  <Layout>
    <SEO title="Inicio" lang="es" />
    <Banner />
    <Motivation />
    <Features />
    <Product />
    <Contact />
    <About />
    <Footer />
  </Layout>
)

export default IndexPage
