import React from "react"

const CtaButton = () => {
  return (
    <a
      href="#contact-section"
      className=" btn-cta  text-white 
      bg-yellow-sun  pt-2 pb-2 text-white tracking-wide text-lg rounded px-4 shadow-xl inline-block"
    >
      Solicitar Sitio Web &#8594;
    </a>
  )
}

export default CtaButton
