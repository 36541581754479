import React from "react"
import ListItemWithIcon from "./list-item-with-icon"
import PhoneIcon from "./icons/phone-icon"
import EnvelopeIcon from "./icons/envelope-icon"
const Contact = () => {
  return (
    <div id="contact-section" className="md:relative md:h-screen ">
      <div className="hidden md:block contact-section-content-masking md:absolute left-0 top-0 w-full h-screen z-10"></div>
      <div className="contact-section-content  md:absolute top-4 left-0 w-full h-full z-20 md:bg-transparent">
        <div className=" text-white py-16 px-16 md:py-8 md:mt-10 bg-mortino-800 md:bg-transparent">
          <h1 className="sm:text-center font-sans text-bold text-2xl">
            Solicita tu Sitio Web hoy mismo!
          </h1>
          <p className="text-gray-100 max-w-2xl sm:text-center mt-4 mx-auto">
            Tu presencia online a través del mejor producto Web. Te brindamos la
            mejor atención y soporte técnico de calidad.
          </p>
          <div className="mt-8 flex flex-wrap ">
            <div className="mr-4">
              <ListItemWithIcon
                align="center"
                detail={
                  <>
                    <a href="tel:0968741465">0968741465</a> /{" "}
                    <a href="tel:2401862">02401862</a>
                  </>
                }
                icon={<PhoneIcon />}
              />
            </div>
            <div>
              <ListItemWithIcon
                align="center"
                detail={
                  <a href="mailto:jfandradea@gmail.com">jfandradea@gmail.com</a>
                }
                icon={<EnvelopeIcon />}
              />
            </div>
          </div>

          <p className="px-2 text-sm max-w-xl">
            Envíanos tus preguntas, comentarios, requerimientos, etc; mediante
            el siguiente formulario:
          </p>

          <form
            name="contact"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            className="contact-form px-2 mt-4"
            method="post"
          >
            <input type="hidden" name="form-name" value="contact" />
            <label for="name">Nombre</label>
            <input type="text" name="name" id="name" />
            <label for="email">Email</label>
            <input type="email" name="email" id="email" required />
            <label for="comments">Comentarios</label>
            <textarea
              name="comments"
              id="comments"
              className="focus:shadow-outline text-mortino-copy py-2 px-4 w-full md:w-3/4 h-24 border-b-4 border-yellow-desert lg:w-1/2"
            ></textarea>
            <button className="event-variation ">Enviar &#8594;</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Contact
