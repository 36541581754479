import React from "react"

const CircledIcon = ({ icon, size }) => {
  return <div className={`circled-icon-holder icon-${size}`}>{icon}</div>
}

CircledIcon.defaultProps = {
  size: "md",
}

export default CircledIcon
