import React from "react"

const FeaturesCard = ({ children }) => {
  return (
    <div className="lg:flex-1 bg-mortino-700 mt-4 lg:w-1/2 shadow-inner mx-2 rounded-lg ">
      {children}
    </div>
  )
}

export default FeaturesCard
