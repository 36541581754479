import PropTypes from "prop-types"
import React from "react"

const Header = () => {
  return (
    <div className="content-wrapper">
      <header className="pt-8 md:pt-4 text-center md:flex flex-wrap justify-between h-16 items-center ">
        <div className=" mb-8 md:mb-0 ">
          <h1 className="font-sans font-bold italic text-mortino-500 text-xl">
            Sitios Web
            <span className="text-mortino-800 "> Para Todos</span>
          </h1>
        </div>
        <nav className="mt-4 md:mt-0 max-w-sm mx-auto md:mx-0 md:max-w-full">
          <ul className="nav-items text-mortino-800">
            <li>
              <a href="#about-web-section" className="link">
                Producto Web
              </a>
            </li>
            <li>
              <a href="#features-section" className="link">
                Características
              </a>
            </li>
            <li>
              <a href="#benefits-section" className="link">
                Beneficios
              </a>
            </li>
            <li className="sm:mt-4 md:mt-0">
              <a href="#contact-section" className="link">
                Contacto
              </a>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
