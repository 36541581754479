import React from "react"

const MotivationItem = ({ children }) => {
  return (
    <div className="p-2 flex justify-between   items-start mb-4">
      <div className=" mr-4">
        <svg
          className="w-8 fill-current text-mortino-copy max-w-sm mt-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z" />
        </svg>
      </div>
      <div>
        <p className="justified-text ">{children}</p>
      </div>
    </div>
  )
}

export default MotivationItem
